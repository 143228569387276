<template>
    <div class="full-page" :class="{ 'nav-open': $sidebar.showSidebar }">
<!--        <video autoplay muted loop id="bg_video">&ndash;&gt;-->
            <!--                <source src="/video/frontend_bg.mp4" type="video/mp4" />-->
            <!--            </video>-->
            <md-toolbar md-elevation="0" class="md-transparent md-toolbar-absolute">
                <div class="md-toolbar-row md-offset">
                    <div class="md-toolbar-section-start">
                        <router-link
                            class="md-list-item-link md-list-item-container md-button-clean"
                            aria-current="page"
                            to="/"
                            style="width: auto"
                        >
                            <img
                                src="/img/logo.png"
                                class="navbar-brand font-weight-bolder ms-lg-0 ms-3 text-white"
                                alt="main_logo"
                                style="height: 50px; padding-right: 10px"
                            />
                        </router-link>
                    </div>
                    <div class="md-toolbar-section-end">
                        <md-button
                            class="md-just-icon md-simple md-round md-toolbar-toggle"
                            :class="{ toggled: $sidebar.showSidebar }"
                            @click="toggleSidebar"
                        >
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                            <span class="icon-bar"></span>
                        </md-button>
                    
                        <div class="md-collapse" :class="{ 'off-canvas-sidebar': responsive }">
                            <ul class="md-list md-theme-default">
                                <li class="md-list-item">
                                    <router-link
                                        class="md-list-item-link md-list-item-container md-button-clean"
                                        aria-current="page"
                                        to="/"
                                    >
                                        <div class="md-list-item-content md-ripple">
                                            <md-icon>home</md-icon>
                                            {{ $t("home.title") }}
                                        </div>
                                    </router-link>
                                </li>
                                <li class="md-list-item">
                                    <router-link
                                        class="md-list-item-link md-list-item-container md-button-clean"
                                        aria-current="page"
                                        to="/create-heroglyph"
                                    >
                                        <div class="md-list-item-content md-ripple">
                                            <md-icon>emoji_symbols</md-icon>
                                            {{ $t("createHeroglyph.title") }}
                                        </div>
                                    </router-link>
                                </li>
                                <li class="md-list-item">
                                    <LocaleSwitcher />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </md-toolbar>
        
            <div class="wrapper wrapper-full-page" @click="toggleSidebarPage">
                <notifications />
                <div class="page-header header-filter" :class="setPageClass" filter-color="black">
                    <div class="container">
                        <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
                            <router-view></router-view>
                        </zoom-center-transition>
                    </div>
                </div>
            </div>
    </div>
</template>
<script>
import $ from "jquery";
import LocaleSwitcher from "@/components/LocaleSwitcher";

import {useHeroglyphStore} from "@/stores/HeroglyphStore";
import { useMediaQuery } from '@vueuse/core'


export default {
    setup() {
        const store = useHeroglyphStore();
    },
    props: {
        backgroundColor: {
            type: String,
            default: "black",
        },
    },
    inject: {
        autoClose: {
            default: true,
        },
    },
    data() {
        return {
            responsive: false,
            showMenu: false,
            menuTransitionDuration: 250,
            pageTransitionDuration: 300,
            year: new Date().getFullYear(),
            isLargeScreen: useMediaQuery('(min-width: 991px)')
        };
    },
    computed: {
        setBgImage() {
            let images = {
                Frontend: process.env.VUE_APP_BASE_URL + "/img/frontend_background.png",
            };
            return {
                backgroundImage: `url(${images["Frontend"]})`,
            };
        },
        setPageClass() {
            return `${this.$route.name}-page`.toLowerCase();
        },
    },
    methods: {
        // logout() {
        //     this.$store.dispatch("logout");
        // },
        toggleSidebarPage() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.displaySidebar(false);
            }
        },
        linkClick() {
            if (this.autoClose && this.$sidebar && this.$sidebar.showSidebar === true) {
                this.$sidebar.displaySidebar(false);
            }
        },
        toggleSidebar() {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        toggleNavbar() {
            document.body.classList.toggle("nav-open");
            this.showMenu = !this.showMenu;
        },
        closeMenu() {
            document.body.classList.remove("nav-open");
            this.showMenu = false;
        },
        onResponsiveInverted() {
            if (window.innerWidth < 991) {
                this.responsive = true;
            } else {
                this.responsive = false;
            }
        },
    },
    mounted() {
        this.onResponsiveInverted();
        window.addEventListener("resize", this.onResponsiveInverted);
    },
    beforeDestroy() {
        this.closeMenu();
        window.removeEventListener("resize", this.onResponsiveInverted);
    },
    beforeRouteUpdate(to, from, next) {
        // Close the mobile menu first then transition to next page
        if (this.showMenu) {
            this.closeMenu();
            setTimeout(() => {
                next();
            }, this.menuTransitionDuration);
        } else {
            next();
        }
    },
    components: { LocaleSwitcher },
};
</script>
<style lang="scss" scoped>
$scaleSize: 0.1;
$zoomOutStart: 0.7;
$zoomOutEnd: 0.46;
@keyframes zoomIn8 {
    from {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
    100% {
        opacity: 1;
    }
}
.wrapper-full-page .zoomIn {
    animation-name: zoomIn8;
}
@keyframes zoomOut8 {
    from {
        opacity: 1;
        transform: scale3d($zoomOutStart, $zoomOutStart, $zoomOutStart);
    }
    to {
        opacity: 0;
        transform: scale3d($zoomOutEnd, $zoomOutEnd, $zoomOutEnd);
    }
}
.wrapper-full-page .zoomOut {
    animation-name: zoomOut8;
}
    .mobile {
        font-size: 30px;
        margin-top: 30px;
    }
.copyright {
    width: 100%;
}
</style>
