//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";
import FrontendLayout from "../pages/Frontend/Layout/FrontendLayout";
import i18n from '@/i18n.js';

const routes = [
    {
        path: "/",
        component: FrontendLayout,
        children: [
            {
                path: "/",
                name: i18n.t('home.title'),
                component: () => import("@/pages/Frontend/Home.vue"),
            },
            {
                path: "/create-heroglyph",
                name: i18n.t('createHeroglyph.title'),
                component: () => import("@/pages/Frontend/CreateOwnHeroglyph.vue"),
            },
            {
                path: "/payment/:id",
                name: i18n.t('payment.title'),
                component: () => import("@/pages/Frontend/PayBy.vue"),
            },
            {
                path: "/thankyou/:id",
                name: i18n.t('thankYou.title'),
                component: () => import("@/pages/Frontend/ThankYou.vue"),
            },
            {
                path: "/transparent-treasure/",
                name: i18n.t('transparentTreasure.title'),
                component: () => import("@/pages/Frontend/TransparentTreasure.vue"),
            },
            {
                path: "/referral/:id",
                name: i18n.t('referral.title'),
                component: () => import("@/pages/Frontend/Home.vue"),
            },
            // {
            //     path: "/login",
            //     name: "Login",
            //     component: () => import("@/pages/Frontend/Login.vue"),
            //     meta: { middleware: guest },
            // },
            // {
            //     path: "/register",
            //     name: "Register",
            //     component: () => import("@/pages/Frontend/Register.vue"),
            //     meta: { middleware: guest },
            // },
        ],
    },
];

export default routes;
