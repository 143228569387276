import Vue from "vue";
import axios from "axios";

// Plugins
import App from "./App.vue";
import Chartist from "chartist";
import VueAxios from "vue-axios";
import DashboardPlugin from "./material-dashboard";
import VueMeta from "vue-meta";
import { createPinia, PiniaVuePlugin } from "pinia";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import 'regenerator-runtime/runtime';
import VueHtml2Canvas from 'vue-html2canvas';

// Localization
import i18n from "./i18n";
import VueFlags from "@growthbunker/vueflags";

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueAxios, axios);
Vue.use(VueFlags);
Vue.use(VueMeta, { keyName: "head" });
Vue.use(PiniaVuePlugin);
Vue.use(VueHtml2Canvas);

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
Vue.component("v-select", vSelect);

const pinia = createPinia();
pinia.use( piniaPluginPersistedState );

// router & store setup
import router from "./router";

// global library setup
Vue.prototype.$Chartist = Chartist;

/* eslint-disable no-new */
const app = new Vue({
    router: router,
    el: "#app",
    i18n,
    pinia,
    render: (h) => h(App),
});
