import { defineStore } from "pinia";

export const heroglyphStore = defineStore('heroglyph', {
   state: () => ({
       gender: [],
       origin: [],
       colour: [],
       house: [],
       patron: [],
       character: [],
       dogownerName: [],
       yearOfBirth: [],
       dogCount: [],
       dogName: [],
       dogPhotoId: [],
   })
});