<template>
    <router-view></router-view>
</template>

<script>
    export default {
   
    metaInfo() {
        return {
            script: (function () {})(),
        };
    },
};
</script>
<style lang="scss">
    html {
        background-color: #000000 !important;
    }
    body {
        background-image: url('~@/assets/images/frontend_background.webp');
        background-image: url('~@/assets/images/frontend_background.png');
    
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-attachment: fixed !important;
        background-position: 0 0 !important;
    }
    
    .wrapper-full-page {
        display: flex;
        justify-content: center;
        padding-top: 70px;
        .md-card-wizard {
            width: 800px;
        }
    }
    @media screen and (max-width: 992px) {
        h2, .h2 {
            font-size: 2.05rem;
            padding-bottom: 0.3rem;
        }
        .md-toolbar-section-start {
            flex: auto !important;
        }
        .md-card-actions {
        
        }
        .color-container .heroglyph-icon {
            margin: auto;
        }
    }
    
</style>