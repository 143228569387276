<template>
    <div class="home">
        <SwitchLang @changed="onChange" :initLang="initLang" :options="options" :allow-empty="false"/>
    </div>
</template>

<script>
import { getSupportedLocales } from "@/util/i18n/supported-locales";
import SwitchLang from "vue-switch-lang2";
import $ from "jquery";

let currentLang;
let langOptions = [];

export default {
    beforeCreate() {
        let locale = this.$i18n.locale;
        $(getSupportedLocales()).each(function (k,v) {
            if( v.code == locale ){
                currentLang = {
                    title: v.code,
                    flag: v.flag
                };
            }
            langOptions.push({
                title: v.code,
                flag: v.flag
            })
        })
    },
    data: () => ({
        initLang: currentLang,
        options: langOptions
    }),
    methods: {
        onChange(lang) {
            localStorage.setItem("language", lang.title);
            this.$i18n.locale = lang.title;
        }
    },
    components: {
        SwitchLang
    },
};
</script>
<style>
    .multiselect__content {
        list-style-type: none;
        padding: 0;
        position: absolute;
        left: -1rem;
        /*background: linear-gradient(180deg, rgba(41,40,24,1) 0%, rgba(21,13,9,1) 100%);*/
        /*border: 1px solid #514536;*/
    }
    .multiselect__content li {
        cursor: pointer;
    }
    .multiselect__content .normal-flag{
        margin: 0.3rem !important;
    }
    .multiselect__option--selected div{
        background-color: rgba(249, 218, 126, 0.3490196078) !important;
    }

    .multiselect__option--highlight div {
        background-color: rgba(249, 218, 126, 0.568627451);
        
    }
</style>