import { defineStore } from "pinia";
import heroglyphs from "@/data/heroglyphs.json";
import axios from "axios";
const url = process.env.VUE_APP_API_BASE_URL;

const options = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    crossdomain: true,
};

export const useHeroglyphStore = defineStore( 'heroglyphStore', {

    state: () => ({
        heroglyphId: null,
        userId: null,
        activeStep: 0,
        firstStep: {
            dogRegularName: '',
            dogDate: '',
            living: 'Legend',
        },
        secondStep: {
            isMale: true
        },
        thirdStep: {
            isRescued: true
        },
        fourthStep: {
            colour: 'Bright',
        },
        fifthStep: {
            house: 'Of mix',
        },
        sixthStep: {
          size: '',
        },
        sevenStep: {
            character1: '',
            character2: '',
        },
        eightStep: {
            isHumanMale: true,
        },
        nineStep: {
            zodiac: '',
        },
        tenStep: {
            firstName: '',
            firstNameSVG: '',
            firstNameCode: '',
            email: '',
            birthYear: '',
            zodiac: '',
            dogsCount: '',
            dogsCountFormated: '',
            dogImage: ''
        },
        heroglyphs: {
            firstHeroglyph: {svg: heroglyphs['Gender']['Male'].svg, className: '', code: ''},
            secondHeroglyph: {svg: heroglyphs['Origin']['Rescued'].svg, className: '', code: ''},
            thirdHeroglyph: {svg: heroglyphs['Colour']['Dark'].svg, className: '', code: ''},
            fourthHeroglyph: {svg: heroglyphs['House']['Of dogo'].svg, className: '', code: ''},
            fifthHeroglyph: {svg: heroglyphs['HektorSize'].svg, className: '', code: ''},
            sixthHeroglyph: {svg: heroglyphs['Owner gender']['Man'].svg, className: '', code: ''},
            sevenHeroglyph: {svg: heroglyphs['Chinese zodiac']['Rooster'].svg, className: '', code: ''},
            eightHeroglyph: {svg: heroglyphs['Name']['M'].svg, className: '', code: ''},
            ninthHeroglyph: {svg: heroglyphs['Zodiac']['Leo'].svg, className: '', code: ''},
            tenthHeroglyph: {svg: heroglyphs['Number']['1'].svg, className: '', code: ''},
            eleventhHeroglyph: {svg: heroglyphs['Hektor'].svg, className: '', code: ''},
            twelfthHeroglyph: {svg: heroglyphs['Character']['Poseidog'].svg, className: '', code: ''},
        },
        buy: {
            price: 22,
            balance: 0,
            population: 0,
        },
        billing: {
            fullName: '',
            email: '',
            city: '',
            street: '',
            country: '',
            payBy: 'Stripe',
        }
    }),
    persist: false,
    actions: {
        setStepsData( step, key, value ){
          this[step][key] = value;
        },
        setActiveStep(value) {
            this.activeStep = parseInt(value);
        },
        switchActiveTab() {
            return new Promise((resolve, reject) => {
                switch (this.activeStep) {
                    case 0:
                        this.heroglyphs.firstHeroglyph = {svg: heroglyphs['Gender']['Male'].svg, className: ''};
                        this.heroglyphs.secondHeroglyph = {svg: heroglyphs['Origin']['Rescued'].svg, className: ''};
                        this.heroglyphs.thirdHeroglyph = {svg: heroglyphs['Colour']['Dark'].svg, className: ''};
                        this.heroglyphs.fourthHeroglyph = {svg: heroglyphs['House']['Of dogo'].svg, className: ''};
                        this.heroglyphs.fifthHeroglyph = {svg: heroglyphs['HektorSize'].svg, className: ''};
                        this.heroglyphs.sixthHeroglyph = {svg: heroglyphs['Owner gender']['Man'].svg, className: ''};
                        this.heroglyphs.sevenHeroglyph = {svg: heroglyphs['Chinese zodiac']['Rooster'].svg, className: ''};
                        this.heroglyphs.eightHeroglyph = {svg: heroglyphs['Name']['M'].svg, className: ''};
                        this.heroglyphs.ninthHeroglyph = {svg: heroglyphs['Zodiac']['Leo'].svg, className: ''};
                        this.heroglyphs.tenthHeroglyph = {svg: heroglyphs['Number']['1'].svg, className: ''};
                        this.heroglyphs.eleventhHeroglyph = {svg: heroglyphs['Hektor'].svg, className: ''};
                        this.heroglyphs.twelfthHeroglyph = {svg: heroglyphs['Character']['Poseidog'].svg, className: ''};
                        break;
                    case 1:
                        this.heroglyphs.firstHeroglyph = {svg: heroglyphs['Gender']['Male'].svg, className: ''};
                        this.heroglyphs.secondHeroglyph = {svg: heroglyphs['Origin']['Rescued'].svg, className: ''};
                        this.heroglyphs.thirdHeroglyph = {svg: heroglyphs['Colour']['Dark'].svg, className: ''};
                        this.heroglyphs.fourthHeroglyph = {svg: heroglyphs['House']['Of dogo'].svg, className: ''};
                        this.heroglyphs.fifthHeroglyph = {svg: heroglyphs['HektorSize'].svg, className: ''};
                        this.heroglyphs.sixthHeroglyph = {svg: heroglyphs['Owner gender']['Man'].svg, className: ''};
                        this.heroglyphs.sevenHeroglyph = {svg: heroglyphs['Chinese zodiac']['Rooster'].svg, className: ''};
                        this.heroglyphs.eightHeroglyph = {svg: heroglyphs['Name']['M'].svg, className: ''};
                        this.heroglyphs.ninthHeroglyph = {svg: heroglyphs['Zodiac']['Leo'].svg, className: ''};
                        this.heroglyphs.tenthHeroglyph = {svg: heroglyphs['Number']['1'].svg, className: ''};
                        this.heroglyphs.eleventhHeroglyph = {svg: heroglyphs['Hektor'].svg, className: ''};
                        this.heroglyphs.twelfthHeroglyph = {svg: heroglyphs['Character']['Poseidog'].svg, className: ''};
                        break;
                    case 2:
                        this.heroglyphs.firstHeroglyph = {svg: heroglyphs['Gender']['Male'].svg, className: ' skeleton current'};
                        this.heroglyphs.secondHeroglyph = {svg: heroglyphs['Origin']['Rescued'].svg, className: ' skeleton'};
                        this.heroglyphs.thirdHeroglyph = {svg: heroglyphs['Colour']['Dark'].svg, className: ' skeleton'};
                        this.heroglyphs.fourthHeroglyph = {svg: heroglyphs['House']['Of dogo'].svg, className: ' skeleton'};
                        this.heroglyphs.fifthHeroglyph = {svg: heroglyphs['HektorSize'].svg, className: ' skeleton'};
                        this.heroglyphs.sixthHeroglyph = {svg: heroglyphs['Owner gender']['Man'].svg, className: ' skeleton'};
                        this.heroglyphs.sevenHeroglyph = {svg: heroglyphs['Chinese zodiac']['Rooster'].svg, className: ' skeleton'};
                        this.heroglyphs.eightHeroglyph = {svg: heroglyphs['Name']['M'].svg, className: ' skeleton'};
                        this.heroglyphs.ninthHeroglyph = {svg: heroglyphs['Zodiac']['Leo'].svg, className: ' skeleton'};
                        this.heroglyphs.tenthHeroglyph = {svg: heroglyphs['Number']['1'].svg, className: ' skeleton'};
                        this.heroglyphs.eleventhHeroglyph = {svg: heroglyphs['Hektor'].svg, className: ' skeleton'};
                        this.heroglyphs.twelfthHeroglyph = {svg: heroglyphs['Character']['Poseidog'].svg, className: ' skeleton'};
                        break;
                    case 3:
                        this.heroglyphs.firstHeroglyph = {svg: heroglyphs['Gender'][( this.secondStep.isMale ? 'Male' : 'Female')].svg, className: ''};
                        this.heroglyphs.secondHeroglyph = {svg: heroglyphs['Origin']['Rescued'].svg, className: ' skeleton current'};
                        this.heroglyphs.thirdHeroglyph = {svg: heroglyphs['Colour']['Dark'].svg, className: ' skeleton'};
                        this.heroglyphs.fourthHeroglyph = {svg: heroglyphs['House']['Of dogo'].svg, className: ' skeleton'};
                        this.heroglyphs.fifthHeroglyph = {svg: heroglyphs['HektorSize'].svg, className: ' skeleton'};
                        this.heroglyphs.sixthHeroglyph = {svg: heroglyphs['Owner gender']['Man'].svg, className: ' skeleton'};
                        this.heroglyphs.sevenHeroglyph = {svg: heroglyphs['Chinese zodiac']['Rooster'].svg, className: ' skeleton'};
                        this.heroglyphs.eightHeroglyph = {svg: heroglyphs['Name']['M'].svg, className: ' skeleton'};
                        this.heroglyphs.ninthHeroglyph = {svg: heroglyphs['Zodiac']['Leo'].svg, className: ' skeleton'};
                        this.heroglyphs.tenthHeroglyph = {svg: heroglyphs['Number']['1'].svg, className: ' skeleton'};
                        this.heroglyphs.eleventhHeroglyph = {svg: heroglyphs['Hektor'].svg, className: ' skeleton'};
                        this.heroglyphs.twelfthHeroglyph = {svg: heroglyphs['Character']['Poseidog'].svg, className: ' skeleton'};
                        break;
                    case 4:
                        this.heroglyphs.firstHeroglyph = {svg: heroglyphs['Gender'][( this.secondStep.isMale ? 'Male' : 'Female')].svg, className: ''};
                        this.heroglyphs.secondHeroglyph = {svg: heroglyphs['Origin'][( this.thirdStep.isRescued ? 'Rescued' : 'Babyhood')].svg, className: ''};
                        this.heroglyphs.thirdHeroglyph = {svg: heroglyphs['Colour']['Dark'].svg, className: ' skeleton current'};
                        this.heroglyphs.fourthHeroglyph = {svg: heroglyphs['House']['Of dogo'].svg, className: ' skeleton'};
                        this.heroglyphs.fifthHeroglyph = {svg: heroglyphs['HektorSize'].svg, className: ' skeleton'};
                        this.heroglyphs.sixthHeroglyph = {svg: heroglyphs['Owner gender']['Man'].svg, className: ' skeleton'};
                        this.heroglyphs.sevenHeroglyph = {svg: heroglyphs['Chinese zodiac']['Rooster'].svg, className: ' skeleton'};
                        this.heroglyphs.eightHeroglyph = {svg: heroglyphs['Name']['M'].svg, className: ' skeleton'};
                        this.heroglyphs.ninthHeroglyph = {svg: heroglyphs['Zodiac']['Leo'].svg, className: ' skeleton'};
                        this.heroglyphs.tenthHeroglyph = {svg: heroglyphs['Number']['1'].svg, className: ' skeleton'};
                        this.heroglyphs.eleventhHeroglyph = {svg: heroglyphs['Hektor'].svg, className: ' skeleton'};
                        this.heroglyphs.twelfthHeroglyph = {svg: heroglyphs['Character']['Poseidog'].svg, className: ' skeleton'};
                        break;
                    case 5:
                        this.heroglyphs.firstHeroglyph = {svg: heroglyphs['Gender'][( this.secondStep.isMale ? 'Male' : 'Female')].svg, className: ''};
                        this.heroglyphs.secondHeroglyph = {svg: heroglyphs['Origin'][( this.thirdStep.isRescued ? 'Rescued' : 'Babyhood')].svg, className: ''};
                        this.heroglyphs.thirdHeroglyph = {svg: heroglyphs['Colour'][this.fourthStep.colour].svg, className: ''};
                        this.heroglyphs.fourthHeroglyph = {svg: heroglyphs['House']['Of dogo'].svg, className: ' skeleton current'};
                        this.heroglyphs.fifthHeroglyph = {svg: heroglyphs['HektorSize'].svg, className: ' skeleton'};
                        this.heroglyphs.sixthHeroglyph = {svg: heroglyphs['Owner gender']['Man'].svg, className: ' skeleton'};
                        this.heroglyphs.sevenHeroglyph = {svg: heroglyphs['Chinese zodiac']['Rooster'].svg, className: ' skeleton'};
                        this.heroglyphs.eightHeroglyph = {svg: heroglyphs['Name']['M'].svg, className: ' skeleton'};
                        this.heroglyphs.ninthHeroglyph = {svg: heroglyphs['Zodiac']['Leo'].svg, className: ' skeleton'};
                        this.heroglyphs.tenthHeroglyph = {svg: heroglyphs['Number']['1'].svg, className: ' skeleton'};
                        this.heroglyphs.eleventhHeroglyph = {svg: heroglyphs['Hektor'].svg, className: ' skeleton'};
                        this.heroglyphs.twelfthHeroglyph = {svg: heroglyphs['Character']['Poseidog'].svg, className: ' skeleton'};
                        break;
                    case 6:
                        this.heroglyphs.firstHeroglyph = {svg: heroglyphs['Gender'][( this.secondStep.isMale ? 'Male' : 'Female')].svg, className: ''};
                        this.heroglyphs.secondHeroglyph = {svg: heroglyphs['Origin'][( this.thirdStep.isRescued ? 'Rescued' : 'Babyhood')].svg, className: ''};
                        this.heroglyphs.thirdHeroglyph = {svg: heroglyphs['Colour'][this.fourthStep.colour].svg, className: ''};
                        this.heroglyphs.fourthHeroglyph = {svg: heroglyphs['House'][this.fifthStep.house].svg, className: ''};
                        this.heroglyphs.fifthHeroglyph = {svg: heroglyphs['HektorSize'].svg, className: ' skeleton current'};
                        this.heroglyphs.sixthHeroglyph = {svg: heroglyphs['Owner gender']['Man'].svg, className: ' skeleton'};
                        this.heroglyphs.sevenHeroglyph = {svg: heroglyphs['Chinese zodiac']['Rooster'].svg, className: ' skeleton'};
                        this.heroglyphs.eightHeroglyph = {svg: heroglyphs['Name']['M'].svg, className: ' skeleton'};
                        this.heroglyphs.ninthHeroglyph = {svg: heroglyphs['Zodiac']['Leo'].svg, className: ' skeleton'};
                        this.heroglyphs.tenthHeroglyph = {svg: heroglyphs['Number']['1'].svg, className: ' skeleton'};
                        this.heroglyphs.eleventhHeroglyph = {svg: heroglyphs['Hektor'].svg, className: ' skeleton'};
                        this.heroglyphs.twelfthHeroglyph = {svg: heroglyphs['Character']['Poseidog'].svg, className: ' skeleton'};
                        break;
                    case 7:
                        this.heroglyphs.firstHeroglyph = {svg: heroglyphs['Gender'][( this.secondStep.isMale ? 'Male' : 'Female')].svg, className: ''};
                        this.heroglyphs.secondHeroglyph = {svg: heroglyphs['Origin'][( this.thirdStep.isRescued ? 'Rescued' : 'Babyhood')].svg, className: ''};
                        this.heroglyphs.thirdHeroglyph = {svg: heroglyphs['Colour'][this.fourthStep.colour].svg, className: ''};
                        this.heroglyphs.fourthHeroglyph = {svg: heroglyphs['House'][this.fifthStep.house].svg, className: ''};
                        this.heroglyphs.fifthHeroglyph = {svg: heroglyphs['Size'][this.sixthStep.size].svg, className: ''};
                        this.heroglyphs.sixthHeroglyph = {svg: heroglyphs['Owner gender']['Man'].svg, className: ' skeleton'};
                        this.heroglyphs.sevenHeroglyph = {svg: heroglyphs['Chinese zodiac']['Rooster'].svg, className: ' skeleton'};
                        this.heroglyphs.eightHeroglyph = {svg: heroglyphs['Name']['M'].svg, className: ' skeleton'};
                        this.heroglyphs.ninthHeroglyph = {svg: heroglyphs['Zodiac']['Leo'].svg, className: ' skeleton'};
                        this.heroglyphs.tenthHeroglyph = {svg: heroglyphs['Number']['1'].svg, className: ' skeleton'};
                        this.heroglyphs.eleventhHeroglyph = {svg: heroglyphs['Hektor'].svg, className: ' skeleton current'};
                        this.heroglyphs.twelfthHeroglyph = {svg: heroglyphs['Character']['Poseidog'].svg, className: ' skeleton current'};
                        break;
                    case 8:
                        this.heroglyphs.firstHeroglyph = {svg: heroglyphs['Gender'][( this.secondStep.isMale ? 'Male' : 'Female')].svg, className: ''};
                        this.heroglyphs.secondHeroglyph = {svg: heroglyphs['Origin'][( this.thirdStep.isRescued ? 'Rescued' : 'Babyhood')].svg, className: ''};
                        this.heroglyphs.thirdHeroglyph = {svg: heroglyphs['Colour'][this.fourthStep.colour].svg, className: ''};
                        this.heroglyphs.fourthHeroglyph = {svg: heroglyphs['House'][this.fifthStep.house].svg, className: ''};
                        this.heroglyphs.fifthHeroglyph = {svg: heroglyphs['Size'][this.sixthStep.size].svg, className: ''};
                        this.heroglyphs.sixthHeroglyph = {svg: heroglyphs['Owner gender']['Man'].svg, className: ' skeleton current'};
                        this.heroglyphs.sevenHeroglyph = {svg: heroglyphs['Chinese zodiac']['Rooster'].svg, className: ' skeleton'};
                        this.heroglyphs.eightHeroglyph = {svg: heroglyphs['Name']['M'].svg, className: ' skeleton'};
                        this.heroglyphs.ninthHeroglyph = {svg: heroglyphs['Zodiac']['Leo'].svg, className: ' skeleton'};
                        this.heroglyphs.tenthHeroglyph = {svg: heroglyphs['Number']['1'].svg, className: ' skeleton'};
                        this.heroglyphs.eleventhHeroglyph = {svg: heroglyphs['Character'][this.sevenStep.character1].svg, className: ''};
                        this.heroglyphs.twelfthHeroglyph = {svg: heroglyphs['Character'][this.sevenStep.character2].svg, className: ''};
                        break;
                    case 9:
                        this.heroglyphs.firstHeroglyph = {svg: heroglyphs['Gender'][( this.secondStep.isMale ? 'Male' : 'Female')].svg, className: ''};
                        this.heroglyphs.secondHeroglyph = {svg: heroglyphs['Origin'][( this.thirdStep.isRescued ? 'Rescued' : 'Babyhood')].svg, className: ''};
                        this.heroglyphs.thirdHeroglyph = {svg: heroglyphs['Colour'][this.fourthStep.colour].svg, className: ''};
                        this.heroglyphs.fourthHeroglyph = {svg: heroglyphs['House'][this.fifthStep.house].svg, className: ''};
                        this.heroglyphs.fifthHeroglyph = {svg: heroglyphs['Size'][this.sixthStep.size].svg, className: ''};
                        this.heroglyphs.sixthHeroglyph = {svg: heroglyphs['Owner gender'][( this.eightStep.isHumanMale ? 'Man' : 'Woman')].svg, className: ''};
                        this.heroglyphs.sevenHeroglyph = {svg: heroglyphs['Chinese zodiac']['Rooster'].svg, className: ' skeleton'};
                        this.heroglyphs.eightHeroglyph = {svg: heroglyphs['Name']['M'].svg, className: ' skeleton'};
                        this.heroglyphs.ninthHeroglyph = {svg: heroglyphs['Zodiac']['Leo'].svg, className: ' skeleton current'};
                        this.heroglyphs.tenthHeroglyph = {svg: heroglyphs['Number']['1'].svg, className: ' skeleton'};
                        this.heroglyphs.eleventhHeroglyph = {svg: heroglyphs['Character'][this.sevenStep.character1].svg, className: ''};
                        this.heroglyphs.twelfthHeroglyph = {svg: heroglyphs['Character'][this.sevenStep.character2].svg, className: ''};
                        break;
                    case 10:
                        this.heroglyphs.firstHeroglyph = {svg: heroglyphs['Gender'][( this.secondStep.isMale ? 'Male' : 'Female')].svg, className: ''};
                        this.heroglyphs.secondHeroglyph = {svg: heroglyphs['Origin'][( this.thirdStep.isRescued ? 'Rescued' : 'Babyhood')].svg, className: ''};
                        this.heroglyphs.thirdHeroglyph = {svg: heroglyphs['Colour'][this.fourthStep.colour].svg, className: ''};
                        this.heroglyphs.fourthHeroglyph = {svg: heroglyphs['House'][this.fifthStep.house].svg, className: ''};
                        this.heroglyphs.fifthHeroglyph = {svg: heroglyphs['Size'][this.sixthStep.size].svg, className: ''};
                        this.heroglyphs.sixthHeroglyph = {svg: heroglyphs['Owner gender'][( this.eightStep.isHumanMale ? 'Man' : 'Woman')].svg, className: ''};
                        this.heroglyphs.sevenHeroglyph = {svg: heroglyphs['Chinese zodiac']['Rooster'].svg, className: ' skeleton current'};
                        this.heroglyphs.eightHeroglyph = {svg: heroglyphs['Name']['M'].svg, className: ' skeleton'};
                        this.heroglyphs.ninthHeroglyph = {svg: heroglyphs['Zodiac'][this.nineStep.zodiac].svg, className: ''};
                        this.heroglyphs.tenthHeroglyph = {svg: heroglyphs['Number']['1'].svg, className: ' skeleton'};
                        this.heroglyphs.eleventhHeroglyph = {svg: heroglyphs['Character'][this.sevenStep.character1].svg, className: ''};
                        this.heroglyphs.twelfthHeroglyph = {svg: heroglyphs['Character'][this.sevenStep.character2].svg, className: ''};
                        break;
                    case 11:
                        this.heroglyphs.firstHeroglyph = {svg: heroglyphs['Gender'][( this.secondStep.isMale ? 'Male' : 'Female')].svg, className: ''};
                        this.heroglyphs.secondHeroglyph = {svg: heroglyphs['Origin'][( this.thirdStep.isRescued ? 'Rescued' : 'Babyhood')].svg, className: ''};
                        this.heroglyphs.thirdHeroglyph = {svg: heroglyphs['Colour'][this.fourthStep.colour].svg, className: ''};
                        this.heroglyphs.fourthHeroglyph = {svg: heroglyphs['House'][this.fifthStep.house].svg, className: ''};
                        this.heroglyphs.fifthHeroglyph = {svg: heroglyphs['Size'][this.sixthStep.size].svg, className: ''};
                        this.heroglyphs.sixthHeroglyph = {svg: heroglyphs['Owner gender'][( this.eightStep.isHumanMale ? 'Man' : 'Woman')].svg, className: ''};
                        this.heroglyphs.sevenHeroglyph = {svg: heroglyphs['Chinese zodiac'][this.tenStep.zodiac].svg, className: ''};
                        this.heroglyphs.eightHeroglyph = {svg: heroglyphs['Name']['M'].svg, className: ' skeleton current'};
                        this.heroglyphs.ninthHeroglyph = {svg: heroglyphs['Zodiac'][this.nineStep.zodiac].svg,
                            className: '', code: heroglyphs['Zodiac'][this.nineStep.zodiac].code};
                        this.heroglyphs.tenthHeroglyph = {svg: heroglyphs['Number']['1'].svg, className: ' skeleton current'};
                        this.heroglyphs.eleventhHeroglyph = {svg: heroglyphs['Character'][this.sevenStep.character1].svg, className: ''};
                        this.heroglyphs.twelfthHeroglyph = {svg: heroglyphs['Character'][this.sevenStep.character2].svg, className: ''};
                        break;
                    case 12:
                        this.heroglyphs.firstHeroglyph = {svg: heroglyphs['Gender'][( this.secondStep.isMale ? 'Male' : 'Female')].svg,
                            className: '', code: heroglyphs['Gender'][( this.secondStep.isMale ? 'Male' : 'Female')].code};
                        this.heroglyphs.secondHeroglyph = {svg: heroglyphs['Origin'][( this.thirdStep.isRescued ? 'Rescued' : 'Babyhood')].svg,
                            className: '', code: heroglyphs['Origin'][( this.thirdStep.isRescued ? 'Rescued' : 'Babyhood')].code};
                        this.heroglyphs.thirdHeroglyph = {svg: heroglyphs['Colour'][this.fourthStep.colour].svg,
                            className: '', code: heroglyphs['Colour'][this.fourthStep.colour].code};
                        this.heroglyphs.fourthHeroglyph = {svg: heroglyphs['House'][this.fifthStep.house].svg,
                            className: '', code: heroglyphs['House'][this.fifthStep.house].code};
                        this.heroglyphs.fifthHeroglyph = {svg: heroglyphs['Size'][this.sixthStep.size].svg,
                            className: '', code: heroglyphs['Size'][this.sixthStep.size].code};
                        this.heroglyphs.sixthHeroglyph = {svg: heroglyphs['Owner gender'][( this.eightStep.isHumanMale ? 'Man' : 'Woman')].svg,
                            className: '', code: heroglyphs['Owner gender'][( this.eightStep.isHumanMale ? 'Man' : 'Woman')].code};
                        this.heroglyphs.sevenHeroglyph = {svg: heroglyphs['Chinese zodiac'][this.tenStep.zodiac].svg,
                            className: '', code: heroglyphs['Chinese zodiac'][this.tenStep.zodiac].code};
                        this.heroglyphs.eightHeroglyph = {svg: this.tenStep.firstNameSVG,
                            className: '', code: this.tenStep.firstNameCode};
                        this.heroglyphs.ninthHeroglyph = {svg: heroglyphs['Zodiac'][this.nineStep.zodiac].svg,
                            className: '', code: heroglyphs['Zodiac'][this.nineStep.zodiac].code};
                        this.heroglyphs.tenthHeroglyph = {svg: heroglyphs['Number'][this.tenStep.dogsCountFormated].svg,
                            className: '', code: heroglyphs['Number'][this.tenStep.dogsCountFormated].code};
                        this.heroglyphs.eleventhHeroglyph = {svg: heroglyphs['Character'][this.sevenStep.character1].svg,
                            className: '', code: heroglyphs['Character'][this.sevenStep.character1].code};
                        this.heroglyphs.twelfthHeroglyph = {svg: heroglyphs['Character'][this.sevenStep.character2].svg,
                            className: '', code: heroglyphs['Character'][this.sevenStep.character2].code};
                        break;
                }
                resolve(true);
            });
        },
        saveUserInfo() {
            return new Promise((resolve, reject) => {
                let data = new FormData();
                data.append('id', this.heroglyphId);
                data.append('fullName', this.billing.fullName);
                data.append('city', this.billing.city);
                data.append('street', this.billing.street);
                data.append('country', this.billing.country);
                data.append('payBy', this.billing.payBy);
                data.append('price', this.buy.price);
                data.append('user_locale', localStorage.getItem("language"));
                axios.post(`${url}/user-data`, data, options)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch (error => {
                        alert(error)
                        reject(error);
                });
            });
        },
        async sendData( image ) {
            try {
                let formData = {
                    firstStep: this.firstStep,
                    secondStep: this.secondStep,
                    thirdStep: this.thirdStep,
                    fourthStep: this.fourthStep,
                    fifthStep: this.fifthStep,
                    sixthStep: this.sixthStep,
                    sevenStep: this.sevenStep,
                    eightStep: this.eightStep,
                    nineStep: this.nineStep,
                    tenStep: this.tenStep,
                };
                let data = new FormData();
                if( this.tenStep.dogImage.file !== undefined ){
                    data.append('file', this.tenStep.dogImage.file);
                }
                data.append('heroglyphHorizontal', image, 'heroglyph.png');
                data.append('formData', JSON.stringify(formData));
                data.append('heroglyphs', JSON.stringify(this.heroglyphs));
                data.append('user_locale', localStorage.getItem("language"));
                const response = await axios.post(`${url}/new-heroglyph`, data, options)
                    .then(response => {
                        return response.data;
                    });
                this.heroglyphId = response.heroglyph_id;
                this.userId = response.user_id;
                if( response.user_profile != null ){
                    this.billing.fullName = response.user_profile.full_name;
                    this.billing.city = response.user_profile.city;
                    this.billing.street = response.user_profile.street;
                    this.billing.country = response.user_profile.country;
                }
            } catch (error) {
                alert(error)
            }
        }
    },
    getters: {
        getHeroglyphs(state) {
            return state.heroglyphs;
        }
    }
});