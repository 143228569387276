export default {
    en: {
        name: "English",
        flag: "us"
    },
    sk: {
        name: "Slovak",
        flag: "sk"
    },
    de: {
        name: "German",
        flag: "de"
    },
};
